import { Fragment } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { route } from "preact-router";
import { Text } from "preact-i18n";

import LegacyOrderSummaryItem from "../shared/LegacyOrderSummaryItem.jsx";
import { useMediaQuery } from "react-responsive";

import { Configs } from "../../helpers/context";
import { formatDate } from "../../helpers/formatters/date";
import { formatIntegerCurrencyString } from "../../helpers/formatters";
import { EMIT_LOAD_INVOICE } from "../../helpers/event-definitions";

import PoyntCollectForm from "../PoyntCollectForm/PoyntCollectForm";
import OrderSummary from "../shared/OrderSummary";
import CentralOrderSummary from "../shared/CentralOrderSummary";
import TipsForm from "../shared/TipsForm";

import "../../style/InvoicePage.css";

const InvoicePage = ({
  order,
  payLink,
  product,
  setTransaction,
  setShowError,
  setErrorMsg,
  c2Analytics,
}) => {
  const configsContext = useContext(Configs);
  const [showDetails, setShowDetails] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 820 });

  // if we already paid, we need to go straight to the payment page
  if (payLink?.invoice?.status === "PAID") {
    setTransaction(payLink?.order?.transactions?.[0]);
    route("/transaction-complete");
    return;
  }

  // track custom event on first load
  useEffect(() => {
    if (c2Analytics?.trackCustomEvent) {
      c2Analytics.trackCustomEvent(EMIT_LOAD_INVOICE);
    }
  }, []);

  useEffect(() => {
    setShowDetails(isDesktopOrLaptop);
  }, [isDesktopOrLaptop]);

  const invoiceId = payLink.invoice?.customInvoiceId || payLink.invoice?.orderId?.slice(0, 8);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const header = (
    <Fragment>
      {/* For mobile view, show/hide details button */}
      <div className="mobile-view">
        <button 
          onClick={toggleDetails}
          className="invoice-button"
        >
          {showDetails ? "Hide Details" : "View Details"}
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path d="M15.9419 7.17938C15.8259 7.06812 15.6642 7.00391 15.5 7.00391C15.3359 7.00391 15.1742 7.06812 15.0581 7.17938L10.5 11.5476L5.94192 7.17938C5.82458 7.06774 5.66585 7.00522 5.5005 7.00551C5.33516 7.0058 5.17667 7.06887 5.05975 7.18092C4.94283 7.29297 4.87701 7.44485 4.87671 7.60331C4.87641 7.76177 4.94165 7.91389 5.05814 8.02634L10.0581 12.818C10.1742 12.9293 10.3359 12.9935 10.5 12.9935C10.6642 12.9935 10.8259 12.9293 10.9419 12.818L15.9419 8.02634C16.058 7.91514 16.125 7.76017 16.125 7.60286C16.125 7.44556 16.058 7.29059 15.9419 7.17938Z" fill="#09757A"/>
          </svg>
        </button>
      </div>
      
      {/* For desktop view, or if showDetails is true in mobile view, show full details */}
      {(showDetails || isDesktopOrLaptop) && (
        <div>
          <div className="invoice-details">
            <div>
              <span className="invoice-heading-bill-to">
                <Text id="INVOICE_BILL_TO">Bill To</Text>
              </span>
              <div className="invoice-details-contact">
                {payLink.invoice?.firstName} {payLink.invoice?.lastName}
              </div>
              <div className="invoice-details-contact">{payLink.invoice?.customerEmail}</div>
            </div>
            <div>
              <span className="invoice-details-heading">
                <Text id="INVOICE_INVOICE">Invoice</Text>
              </span>{" "}
              #{invoiceId}
              <div className="invoice-details-sent-info">
                <span className="invoice-details-heading">
                  <Text id="INVOICE_SENT_ON">Sent on</Text>:{" "}
                </span>
                {formatDate(
                  payLink.invoice?.executedAt || payLink.invoice?.createdAt,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                  { dateStyle: "long" },
                )}
              </div>
              <div className={"invoice-details-due-by" + (new Date() > new Date(payLink.invoice?.dueAt) ? " invoice-details-overdue" : "")}>
                <span className="invoice-details-heading">
                  <Text id="INVOICE_DUE_BY">Due by</Text>:{" "}
                </span>
                {formatDate(
                  payLink.invoice?.dueAt || payLink.invoice?.executedAt || payLink.invoice?.createdAt,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                  { dateStyle: "long" },
                )}
              </div>
            </div>
          </div>
          <div class="order-summary-divider"></div>
          {payLink.invoice?.message ? (
            <Fragment>
              <div class="invoice-message">{payLink.invoice.message}</div>
              <div class="order-summary-divider"></div>
            </Fragment>
          ) : null}
          {order?.isLegacy ? (
            <div className="order-items">
              <span className="order-item">
                <Text id="ORDER_ITEM">Item</Text>
              </span>
              {order?.order?.value?.items?.map((item) => {
                return <LegacyOrderSummaryItem item={item} />;
              })}
            </div>
          ) : (
            <CentralOrderSummary order={order}/>
          )}
        </div>
      )}
    </Fragment>
  );

  return (
    <div>
      <div className="invoice-page">
        <div className="collect-form-container">
          <div className="container">
            {!payLink?.metadata?.allowTips ||
            payLink?.metadata?.allowTips === "0" ||
            !payLink?.enableGratuity ||
            payLink?.enableGratuity === "0" ? (
              <div className="collect-form-total">
                <span style="float:left">
                  <Text id="CHECKOUT_TOTAL">Total</Text>
                </span>
                <span style="float:right">
                  {order?.isLegacy
                    ? formatIntegerCurrencyString(
                        order?.order?.value?.amounts?.netTotal,
                        configsContext?.configs?.currency,
                        configsContext?.configs?.ecommerceLocaleLanguage,
                      )
                    : formatIntegerCurrencyString(
                        order?.order?.value?.totals?.total?.value,
                        configsContext?.configs?.currency,
                        configsContext?.configs?.ecommerceLocaleLanguage,
                      )}
                </span>
              </div>
            ) : (
              <div className="invoice-tips-form">
                <TipsForm payLink={payLink} order={order} />
              </div>
            )}
            <PoyntCollectForm
              payLink={payLink}
              order={order}
              product={product}
              setTransaction={setTransaction}
              setShowError={setShowError}
              setErrorMsg={setErrorMsg}
            />
          </div>
        </div>
        <div className="invoice-summary">
          <div className="container">
            <div className="invoice-heading">
              <h1>
                <Text
                  id="INVOICE_FROM"
                  fields={{ businessName: configsContext?.configs?.ecommerceBusinessName }}
                >
                  Invoice from {configsContext?.configs?.ecommerceBusinessName}
                </Text>
              </h1>
              <div className={"invoice-heading-subtext" + (new Date() > new Date(payLink.invoice?.dueAt) ? " invoice-details-overdue" : "")}>
                <Text id="INVOICE_DUE_BY">Due by</Text>{" "}
                {formatDate(
                  payLink.invoice?.dueAt ||
                    payLink.invoice?.executedAt ||
                    payLink.invoice?.createdAt,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                  { dateStyle: "long" },
                )}
              </div>
            </div>
            <div className="container invoice-order-summary">
              {header}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePage;
