import OrderSummaryBreakdown from "./OrderSummaryBreakdown";
import OrderSummaryLineItem from "./OrderSummaryLineItem";

const CentralOrderSummary = ({ order }) => {
  return (
    <Fragment>
      {order?.order?.value?.lineItems?.map((lineItem) => {
        return <OrderSummaryLineItem lineItem={lineItem} />;
      })}
      {order?.order?.value?.showAdjustmentBreakdown ?
        (<OrderSummaryBreakdown
          order={order}
          subTotal={order?.order?.value?.totals?.subTotal?.value}
          total={order?.order?.value?.totals?.transactionAmount}
          adjustments={order?.order?.value?.adjustments}
          tipAmount = {order?.order?.value?.totals?.tipAmount}
        />) :
        (<OrderSummaryAmounts
          discountTotal={order?.order?.value?.totals?.discountTotal?.value}
          feeTotal={order?.order?.value?.totals?.feeTotal?.value}
          taxTotal={order?.order?.value?.totals?.taxTotal?.value}
          subTotal={order?.order?.value?.totals?.subTotal?.value}
          shippingTotal={order?.order?.value?.totals?.shippingTotal?.value}
          tipAmount={order?.order?.value?.totals?.tipAmount}
          total={order?.order?.value?.totals?.transactionAmount}
        />)
      }
    </Fragment>
  );
};

export default CentralOrderSummary;
